$font-family-base: "Calibri", sans-serif;

h1,h2,h3,h4,h5,h6 {
  font-family: "Garamond", serif;
}

@import "node_modules/bootstrap/scss/bootstrap";

ul {
  margin-bottom: 0;
}

hr {
  margin: 0 0 0 0;
}

@media print {
  div {
    break-inside: avoid;
  }
}
